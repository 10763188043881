import { Klubb } from 'src/models/Klubb';
import { Dommer } from './Funksjonaer';
import { Person } from './Person';
import { RekordCandidate } from './Rekord';

export type Stevne = {
  id: number;
  startDato: Nullable<string>;
  sted: string;
  stevnetype: string;
  godkjent: boolean;
  klubb: Klubb;
};

export type StevneDetail = {
  id: number;
  startDato: Nullable<string>;
  sted: string;
  godkjent: boolean;
  stevnetype: StevneType;
  klubb: Klubb;
  puljeList: Pulje[];
};

export type StevneType = {
  id: number;
  aktiv: boolean;
  navn: string;
};

export type Pulje = {
  puljeNr: number;
  dato: string;
  beskrivelse: Nullable<string>;
  funksjonaerList: Funksjonaer[];
  resultatList: NasjonaltResultat[];
};

export type Funksjonaer = {
  id: number;
  personId: number;
  funksjonaerrolle: FunksjonaerRolle;
  navn: string;
};

export type FunksjonaerRolle = {
  id: number;
  navn: string;
};

export type Kategori = {
  id: number;
  alderskategori: string;
  forkortelse: string;
  kjonn: string;
  navn: string;
};

export type Vektklasse = {
  id: number;
  navn: VektklasseType;
  vektklasse: number;
};

export type NasjonaltResultat = {
  id: number;
  person: Person;
  historicKlubb: Klubb;
  historicPersonNavn: string;
  dato: string;
  sted: string;
  startnummer: number;
  kroppsvekt: number;
  vektklasse: Vektklasse;
  kategori: Kategori;
  besteRykk: Nullable<number>;
  besteStot: Nullable<number>;
  rykk1: Nullable<number>;
  rykk2: Nullable<number>;
  rykk3: Nullable<number>;
  stot1: Nullable<number>;
  stot2: Nullable<number>;
  stot3: Nullable<number>;
  total: Nullable<number>;
  poeng: Nullable<number>;
  veteranpoeng: Nullable<number>;
};

type ResultatOrigin = 'NASJONALT' | 'INTERNASJONALT';

export type Resultat = {
  id: number;
  person: Person;
  historicPersonNavn: string;
  historicKlubb: Klubb;
  kategori: Kategori;
  vektklasse: Vektklasse;
  vektklasseSenior: Vektklasse;
  dato: string;
  sted: string;
  kroppsvekt: number;
  rykk1: Nullable<number>;
  rykk2: Nullable<number>;
  rykk3: Nullable<number>;
  stot1: Nullable<number>;
  stot2: Nullable<number>;
  stot3: Nullable<number>;
  besteRykk: Nullable<number>;
  besteStot: Nullable<number>;
  total: Nullable<number>;
  poeng: Nullable<number>;
  veteranpoeng: Nullable<number>;
  origin: ResultatOrigin;
};

export type ResultatList = {
  id: number;
  dato: string;
  historicPersonNavn: string;
  besteRykk: Nullable<number>;
  besteStot: Nullable<number>;
  total: Nullable<number>;
  origin: ResultatOrigin;
};

export type StevneUpload = {
  sted?: string;
  stevnetype?: StevneType;
  arrangorklubb?: Klubb;
  puljeList: PuljeUpload[];
  godkjent?: boolean;
};

export type PuljeUpload = {
  dato?: string;
  puljeNr?: number;
  beskrivelse?: string;
  funksjonaerList?: FunksjonaerUpload[];
  resultatList?: ResultatUpload[];
};

export type FunksjonaerUpload = {
  dommer?: Dommer;
  funksjonaerrolle?: FunksjonaerRolle;
  person?: Person;
};

export type ResultatUpload = {
  person: Nullable<Person>;
  vektklasse: Nullable<Vektklasse>;
  kategori: Nullable<Kategori>;
  kroppsvekt: Nullable<number>;
  startnummer: Nullable<number>;
  rykk1: Nullable<number>;
  rykk2: Nullable<number>;
  rykk3: Nullable<number>;
  stot1: Nullable<number>;
  stot2: Nullable<number>;
  stot3: Nullable<number>;
  poeng: Nullable<number>;
  veteranpoeng: Nullable<number>;
};

export type RankingResultat = {
  id: number;
  navn: string;
  foedselsdato: string;
  kroppsvekt: number;
  dato: string;
  klubbNavn: string;
  besteRykk: Nullable<number>;
  besteStot: Nullable<number>;
  total: Nullable<number>;
  poeng: Nullable<number>;
  veteranpoeng: Nullable<number>;
  vektklasse: string;
  kategori: string;
  stevnetype: string;
  stevnested: string;
};

export type PublicRankingResultat = {
  dato: string;
  navn: string;
  besteRykk: Nullable<number>;
  besteStot: Nullable<number>;
  total: Nullable<number>;
  poeng: Nullable<number>;
  veteranpoeng: Nullable<number>;
  klubbName: string;
  vektklasse: string;
  kategori: string;
  stevnetype: string;
  stevnested: string;
};

export type PublicStevne = {
  id: number;
  startDato: string;
  sted: string;
  stevnetype: string;
  klubbName: string;
  origin: StevneOrigin;
};

type StevneOrigin = 'NORGE' | 'INTERNASJONALT';

export type PublicStevneDetail = {
  id: number;
  startDato: string;
  sted: string;
  godkjent: boolean;
  stevnetype: string;
  klubbName: string;
  puljer: PublicPulje[];
};

export type PublicPulje = {
  puljeNr: number;
  dato: string;
  funksjonaerer: PublicFunksjonaer[];
  resultater: PublicResultat[];
};

export type PublicResultat = {
  besteRykk: number;
  besteStot: number;
  dato: string;
  kategori: Kategori;
  historicKlubb: Klubb;
  historicPersonName: string;
  poeng: Nullable<number>;
  rykk1: Nullable<number>;
  rykk2: Nullable<number>;
  rykk3: Nullable<number>;
  startnummer: number;
  stot1: Nullable<number>;
  stot2: Nullable<number>;
  stot3: Nullable<number>;
  total: Nullable<number>;
  vektklasse: Vektklasse;
  veteranpoeng: Nullable<number>;
};

export type PublicFunksjonaer = {
  funksjonaerName: string;
  dommergrad: Nullable<string>;
  funksjonaerrolle: string;
  klubbName: string;
};

export type StevneResponse = {
  rekordCandidates: RekordCandidate[];
};

export const kategoriTypes = ['S', 'J', 'U', 'V'] as const;
export type KategoriType = (typeof kategoriTypes)[number];
export const KategoriTypeToReadable: { [key in KategoriType]: string } = {
  S: 'Senior',
  J: 'Junior',
  U: 'Ungdom',
  V: 'Veteran',
};

export const veteranAlderskategorierMenn = [
  'M35',
  'M40',
  'M45',
  'M50',
  'M55',
  'M60',
  'M65',
  'M70',
  'M75',
  'M80',
  'M85',
  'M90',
] as const;

export const veteranAlderskategorierKvinner = [
  'K35',
  'K40',
  'K45',
  'K50',
  'K55',
  'K60',
  'K65',
  'K70',
  'K75',
  'K80',
  'K85',
  'K90',
] as const;

export const veteranAlderskategorier = [
  ...veteranAlderskategorierMenn,
  ...veteranAlderskategorierKvinner,
] as const;

export type VeteranAlderskategori = (typeof veteranAlderskategorier)[number];

export const alderskategorier = [
  'UM',
  'UK',
  'JM',
  'JK',
  'SM',
  'SK',
  ...veteranAlderskategorier,
] as const;
export type Alderskategori = (typeof alderskategorier)[number];
export const AlderskategoriToReadable: { [key in Alderskategori]: string } = {
  SM: 'Senior Menn',
  SK: 'Senior Kvinner',
  UM: 'Ungdom Menn',
  UK: 'Ungdom Kvinner',
  JM: 'Junior Menn',
  JK: 'Junior Kvinner',
  M35: 'Veteran Menn over 35',
  M40: 'Veteran Menn over 40',
  M45: 'Veteran Menn over 45',
  M50: 'Veteran Menn over 50',
  M55: 'Veteran Menn over 55',
  M60: 'Veteran Menn over 60',
  M65: 'Veteran Menn over 65',
  M70: 'Veteran Menn over 70',
  M75: 'Veteran Menn over 75',
  M80: 'Veteran Menn over 80',
  M85: 'Veteran Menn over 85',
  M90: 'Veteran Menn over 90',
  K35: 'Veteran Kvinner over 35',
  K40: 'Veteran Kvinner over 40',
  K45: 'Veteran Kvinner over 45',
  K50: 'Veteran Kvinner over 50',
  K55: 'Veteran Kvinner over 55',
  K60: 'Veteran Kvinner over 60',
  K65: 'Veteran Kvinner over 65',
  K70: 'Veteran Kvinner over 70',
  K75: 'Veteran Kvinner over 75',
  K80: 'Veteran Kvinner over 80',
  K85: 'Veteran Kvinner over 85',
  K90: 'Veteran Kvinner over 90',
};

export const vektklasserUngdomKvinner = [
  '40',
  '45',
  '49',
  '55',
  '59',
  '64',
  '71',
  '76',
  '81',
  '+81',
] as const;
type VektklasseUngdomKvinnerTuple = typeof vektklasserUngdomKvinner;
type VektklasseUngdomKvinnerType = VektklasseUngdomKvinnerTuple[number];

export const vektklasserKvinner = [
  '45',
  '49',
  '55',
  '59',
  '64',
  '71',
  '76',
  '81',
  '87',
  '+87',
] as const;
type VektklasseKvinnerTuple = typeof vektklasserKvinner;
type VektklasseKvinnerType = VektklasseKvinnerTuple[number];

export const vektklasserUngdomMenn = [
  '49',
  '55',
  '61',
  '67',
  '73',
  '81',
  '89',
  '96',
  '102',
  '+102',
] as const;
type VektklasseUngdomMennTuple = typeof vektklasserUngdomMenn;
type VektklasseUngdomMannType = VektklasseUngdomMennTuple[number];

export const vektklasserMenn = [
  '55',
  '61',
  '67',
  '73',
  '81',
  '89',
  '96',
  '102',
  '109',
  '+109',
] as const;
type VektklasseMennTuple = typeof vektklasserMenn;
type VektklasseMennType = VektklasseMennTuple[number];

export type VektklasseType =
  | VektklasseUngdomKvinnerType
  | VektklasseKvinnerType
  | VektklasseUngdomMannType
  | VektklasseMennType;
